import DateUtils from "@/services/utils/DateUtils";

describe("DateUtils", () => {
  beforeEach(() => {
    jest.restoreAllMocks();
  });

  describe("daysFromToday", () => {
    it("should return null if priorDate is undefined", () => {
      // given
      const priorDate = undefined;

      // when
      const result = DateUtils.daysFromToday(priorDate);

      // then
      expect(result).toBe(null);
    });

    it("should return the number of days between the priorDate and today", () => {
      // given
      const priorDate = "2022-01-01";
      const today = new Date();
      const oneDay = 24 * 60 * 60 * 1000;
      const diffDays = Math.round(
        (new Date(priorDate).valueOf() - today.valueOf()) / oneDay
      );

      // when
      const result = DateUtils.daysFromToday(priorDate);

      // then
      expect(result).toBe(diffDays);
    });
  });

  describe("dateDiffInterval()", () => {
    it("should return the difference in days if less than a month", () => {
      // given
      const priorDate = new Date();
      priorDate.setDate(priorDate.getDate() - 10);

      // when
      const result = DateUtils.dateDiffInterval(priorDate.toString());

      // then
      expect(result).toEqual({ type: "day", value: 10 });
    });

    it("should return the difference in months if less than a year", () => {
      // given
      const priorDate = new Date();
      priorDate.setMonth(priorDate.getMonth() - 11);

      // when
      const result = DateUtils.dateDiffInterval(priorDate.toString());

      // then
      expect(result).toEqual({ type: "month", value: 11 });
    });

    it("should return the difference in years if greater than a year", () => {
      // given
      const priorDate = new Date();
      priorDate.setFullYear(priorDate.getFullYear() - 3);

      // when
      const result = DateUtils.dateDiffInterval(priorDate.toString());

      // then
      expect(result).toEqual({ type: "year", value: 3 });
    });

    it("should return null if priorDate is undefined", () => {
      // given
      const priorDate = undefined;

      // when
      const result = DateUtils.dateDiffInterval(priorDate);

      // then
      expect(result).toBe(null);
    });
  });

  describe("formatDate", () => {
    it("formats the date correctly", () => {
      const input = "2022-11-28";
      const expectedOutput = { day: 28, month: "november", year: 2022 };
      const result = DateUtils.formatDate(input);
      expect(result).toEqual(expectedOutput);
    });
  });
});
